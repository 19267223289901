import React, { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

export default function SidebarComponent({ closeMenu = () => {} }) {
  const [productPage, setProductPage] = useState([
    "/bopp-tape",
    "/cotton-cloth-tape",
    "/masking-tape",
    "/hdpe-tape",
    "/pet-strap",
    "/silica-gel",
    "/packaging-label",
    "/shrink-pouch",
    "/bubble-roll",
    "/stretch-film",
    "/paper-tube",
    "/esd-accessories",
    "/paint-spray",
    "/stationaries",
    "/cleaning-chemicals",
    "/pp-woven-sacks",
  ]);
  const [products, setProducts] = useState([
    "Bopp Tape",
    "Cotton cloth Tape",
    "Masking Tape",
    "HDPE Tape",
    "PET Strap",
    "Silica Gel",
    "Packaging Labels",
    "Shrink Pouch",
    "Bubble Roll",
    "Stretch Film",
    "Paper Tubes",
    "ESD Accessories",
    "Paint Spray",
    "Stationaries",
    "Cleaning Chemicals",
    "PP woven sacks",
  ]);
  const handleSidebar = () => {
    closeMenu();
  };
  const pageLoad = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Sidebar>
      <Menu transitionDuration={500}>
        <MenuItem component={<Link to="/" />} onClick={pageLoad}>
          HOME
        </MenuItem>
        <MenuItem component={<Link to="/about-us" />} onClick={pageLoad}>
          ABOUT US
        </MenuItem>
        <SubMenu label="PRODUCTS">
          <div style={{ overflowY: "scroll", height: "300px" }}>
            {products.map((product, i) => (
              <MenuItem className="mobile_menuItem" onClick={pageLoad}>
                <NavLink
                  id="mobile_menu"
                  to={productPage[i]}
                  style={{ fontSize: "15px", height: "20px" }}
                  activeClassName="active"
                >
                  {product}
                </NavLink>
              </MenuItem>
            ))}
          </div>
        </SubMenu>
        <MenuItem component={<Link to="/contact" />} onClick={pageLoad}>
          CONTACT
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}
