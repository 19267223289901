import React, { useState } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Product from "./Product";
import Contact from "./Contact";
import Header from "./Header";
import Footer from "./Footer";
import ContactView from "./ContactView";
import ProductInfo from "./ProductInfo";

// import CheckOutPage from "../CheckOutPage/CheckOutPage";
// import LoginPage from "../LoginPage/LoginPage";
// import { useStateValue } from "../StateProvider";
// import { auth } from "../firebase";
// import Payment from "../PaymentPage/Payment";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import Orders from "../OrdersPage/Orders";
// import Footer from "../FooterPage/Footer";

function App() {
  const params = window.location.pathname;
  const [productPage, setProductPage] = useState([
    "/bopp-tape",
    "/cotton-cloth-tape",
    "/masking-tape",
    "/hdpe-tape",
    "/pet-strap",
    "/silica-gel",
    "/packaging-label",
    "/shrink-pouch",
    "/bubble-roll",
    "/stretch-film",
    "/paper-tube",
    "/esd-accessories",
    "/paint-spray",
    "/stationaries",
    "/pp-woven-sacks",
    "/cleaning-chemicals",
  ]);

  const productDetail = [
    [
      "BOPP is biaxially oriented polypropylene (BOPP) film coated with water based adhesive and our adhesive tapes are made from superior quality adhesive, which provides high tack and adhesion strength required for sealing of cartons to make them pilfer proof.",
      "Bopp tape is the commonly used adhesive packing tapes that are used in sealing medium to heavy-duty carton, shipping, inventory management and in logistics industries.",

      "Bopp tape is the most popular product because of the most economy, durable and water proof solution in packaging industries.",
      "It is available in various colors, thickness and sizes.",
      "It is available with company’s own branding to avoid the temper and public image.",
      "It is available in 288mm width (mini jumbo) and 1315mm/1610mm (big jumbo).",
    ],
    [
      "Water proof cloth tape coated with specially formulated waterproof coating compound and rubber solvent acrylic adhesive.",
      "This tape is highly confirmable and adheres well on rough and irregular surfaces.",
      "Strong tack and adhesion properties.",
      "Superb grip on various surfaces due to special rubber adhesive.",
      "High strength tape",
      "Conformable and hand tear able",
      "Waterproof",
    ],
    [
      "Crepe paper as backing and coated with solvent natural rubber adhesive. Easy to tear and no residue.",
      "Widely used for indoor decoration, bundling ,packaging and fixing of light goods.",
      "Automobile industries.",
      "Painting and furniture industries.",
      "Available in any width or length as per customers specifications.",
      "Normal available widths are as: 12mm,18mm,24mm,36mm,48mm,72mm etc...",
    ],
    [
      "We have LDPE protection tapes in transparent, white, black & white and black colors with different thickness, With different Printing. For protection of glasses also, these tapes are used. Depends upon the surfaces to be protected and application we recommend the color of the film and adhesive.",
      "Features : Adjustable peeling force, excellent protection against abrasion and scratches, no adhesive residue, weather resistance, easy to peel off, excellent workability and wide range of thickness.",
      "Usage : Protection of aluminum, S.S or G.I sheets and coils against scratches and dust during transportation/carving/polishing and handling. For anodized/powder coated/PVDF coated aluminum profiles, Polycarbonates/glass and plastic body etc.",
    ],
    [
      "Polyester is the strongest plastic strap. It is often used for heavy loads that require a high tension during handling, transport and storage.",
      "PET straps offer high tension, are able to retain this tension for a long period of time and have an excellent stretch recovery. Because of the stretch recovery, they are excellently resistant to shocks and impacts.",
      "PET strap is light, UV resistant, recyclable and easy to handle. These properties remain, even at high temperatures.",
    ],
    [
      "Silica gel is a form of silicon dioxide that is porous as well as amorphous. It consists of a framework of tridimensional and also irregular geometry of alternating silicon (Si) and oxygen (O) atoms with a scale of nanometer voids and pores.",
      "A desiccant is a hygroscopic material used for maintaining dryness. For manufacturers, the purpose of silica gel is to be used as a desiccant.",
      "Silica gel also serves to keep the relative humidity inside a system of satellite transmission waveguide as low as possible.",
      "In compressed air systems of any industry, silica gel is also used to dry the air. Where a bed of silica gel beads is made for the air from the compressor discharge to flow through it.",
      "The silica gel beads are used for adsorbing the amount of moisture present in the air and preventing it from damaging at the point of use of the compressed air due to condensation.",
    ],
    [
      "Labels that are applied to packaging to indicate the contents, product specifications, contact details, health and safety warnings and guidelines for use, and any additional marketing, branding, or pricing information that applies to a product.",
      "Certain categories of products are subject to legal requirements, which require specific pieces of information to be added before an item can be sold. For example, retailers must display prices and quantities, and manufacturers or distributors must display any relevant health and safety warnings about potentially dangerous products.",
      "Products may need to be labelled with detailed specifications, ingredients and materials used to make the product, model and part numbers, contact information for the manufacturer or supplier, any standards that a product meets, recommended instructions for use, and any applicable health and safety warnings.",

      "Food packaging is particularly regulated, requiring the manufacturer's information, name and description of the product, ingredients, instructions for use and storage, shelf life, place of origin, and allergy information, along with additional information such as nutritional content, consumer guarantees, and batch tracking information.",

      "Packaging labels need to be durable, permanent labels that will allow important information to remain with an item for as long the packaging is kept.",
    ],
    [
      "Choosing the right shrink film for the right applications is important to assure adequate transport and protection of your goods.",
      "Shrink film is a material made up of polymer plastic film. When heat is applied, it shrinks tightly over whatever it is covering. Heat can be applied with a handheld heat gun, or the product and film can be passed through a heat tunnel on a conveyor.",
      "Choosing the right, Shrink Film, Shrink Seal Machine and or Heat Tunnel and combination of, is vital to obtaining quality shrink packaging on your products.",
      "PVC shrink film also works very well with automated machines. Sealing strength, storage issues and sealing by products are of the most common drawbacks of PVC shrink wrap.",
    ],
    [
      "Bubble Wrap or air bubble packing is a transparent plastic packaging material that is mainly used to pack fragile or easily breakable items.",
      "The bubbles have regularly spaced protruding air-filled hemispheres which function as the cushioning agent for sensitive and easily breakable objects.",
      "Small size bubble wrap is usually used to pack delicate items such as electronics, glassware and so on.",
      "Large size bubble wrap films are usually used as a padding for objects such as furniture and other office and household items.",
      "Anti-static bubble wrap is used to wrap electronic items such as television, computers, laptops, cell phones and so on to protect the items against static electricity.",
      "Bubble wrap envelops are used to package books, CDs and even photo frames. The envelope lined with bubble wrap films provides these items with maximum protection against shock and vibrations.",
    ],
    [
      "Stretch film or stretch wrap is a highly stretchable plastic film that is used in packaging and shipping to wrap around items to keep them in place.",
      "The elastic recovery of stretch wrap keeps the items tightly bound. In contrast, shrink wrap is applied loosely around an item and shrinks tightly with heat.",
      "Inventory Control: Unitizing products speeds up control of inventory since large unitized loads can be recognized, counted and managed more easily and individual boxes.",

      "Customer Service: Unitizing products and goods for your customers allows them to offload and move goods through their warehouse more efficiently.",

      "Reduce the risk of pilferage and theft because packages are in a enclosed load and are complicated to remove. The loss of packaged goods is much easier to keep track of if they are shipped out in unit loads rather than individually, which reduces the occurrences of lost or stolen items.",
      "Minimize plant damage by using stretch film. Stretch Film wrapped goods moved by forklift or pallet jack, are less likely to sustain damage than loose boxes carried by hand. Moving a unitized load in a warehouse reduces the risk of product damage.",
      "Minimize your shipment damages with the proper use of stretch film. It is less likely in shipping that dense and or heavy items will be packed on top of palletized unit loads. You stand a greater chance of not being damaged because of this.",
    ],
    [
      "Paper tubes consist of paper or paperboard sheet layers wound together to form strong, hollow, and usually cylindrical shapes.",
      "The paper layers are laminated or bonded together using adhesives. The wall thickness of the tube can vary depending on the number of layers wrapped during manufacturing.",
      "While paper tubes, paper cores and related products are all made from wound plies of paper or paperboard. Paper tubes or cores can be constructed from one, two or many plies of brown kraft paper or paperboard.",

      "The innermost layer or ply, the liner, and the outermost layer, the wrap, can consist of different materials (foil, film, etc.) or specialized paper. The specialized paper and materials can provide water resistance, graphics or labeling, or a specific color.",

      "The two main types of paper tubes and cores include spiral wound and convolute or parallel wound paper tubes. Convolute wound tubes are used in applications requiring high bend strength, crush resistance and dynamic strength.",

      "A spiral wound tube has the paper ply or plies wrapped around at an angle to the tube's axis. In convolute tubes, the outer two edges of the paper strip are wrapped parallel or at a 90-degree angle to the tube’s axis.",
    ],
    [
      "On a basic level, ESD equipment is anything that could ostensibly be used in an electrostatically sensitive work environment, such as a manufacturing floor, clean room, or circuit repair operation.",
      "ESD equipment can include construction tools, safety utilities, employee clothing, and even infrastructure elements, such as grounded power outlets.",
      "Different work environments require different types of ESD equipment, depending on the scope of their operations. For instance, a computer repair shop won’t necessarily require as much specialized equipment as a printed circuit board fabricator.",
      "Some businesses may get away with a simple set of workshop tools, while others may require complex automated machines and gear, such as ionizers and conductive bins.",
    ],
    [
      "Spray paint for glossy finish – it is multipurpose product ideal for metal, wood, paper and plastic surface.",
      "It dries quickly and is easy to use with aerosol/ spray application. Covers product in single coat use only.",
      "It is CFC (Chlorofluorocarbons) free, quick dry and ecofriendly.",
      "It is available in convenient tin packing, which can be used by anyone. With excellent coverage on almost every product, it has maximum durability and best gloss finish.",
      "Available various colors.",
      "Application - Used in home decor, automobiles, offices, industries, domestic items, appliances and almost on all surfaces.",
    ],
    ["All types of stationary items are available"],
    [
      "PP woven bags are bags made of PP plastic in the woven method.",
      "Woven, or weaving is a method by many threads or tapes woven in two directions (warp and weft), to form a fabric for plastic industry needs.",
      "Polypropylene (PP), is one kind of thermoplastic resin material produced by the polymerization of propylene.",
    ],
  ];
  return (
    <div>
      <Routes>
        <Route
          path="/"
          exact
          element={
            <>
              <ContactView />
              <Header />
              <Home />
              <Footer />
            </>
          }
        />
        <Route
          exact
          path="/about-us"
          element={
            <>
              <ContactView />
              <Header />
              <About />
              <Footer />
            </>
          }
        />
        <Route
          exact
          path="/product"
          element={
            <>
              <ContactView />
              <Header />
              <Product />
              <Footer />
            </>
          }
        />
        <Route
          exact
          path="/contact"
          element={
            <>
              <ContactView />
              <Header />
              <Contact />
              <Footer />
            </>
          }
        />
        {productPage.map((path, i) => (
          <Route
            exact
            path={path}
            element={
              <>
                <ContactView />
                <Header />
                <ProductInfo productDetail={productDetail[i]} />
                <Footer />
              </>
            }
          />
        ))}
      </Routes>
    </div>
  );
}

export default App;
