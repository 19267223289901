import React from "react";
import Header from "./Header";
import EnquiryForm from "./EnquiryForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "@mui/material";

function Contact() {
  const isMobileScreen = useMediaQuery("(max-width:800px)");
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        marginRight: "20px",
        justifyContent: "center",
        color: "#262525",
        flexDirection: `${isMobileScreen ? "column-reverse" : "row"}`,
      }}
    >
      <div
        style={{
          width: `${isMobileScreen ? "auto" : "40%"}`,
          padding: "30px",
          // border: "1px solid lightgrey",
          // borderRadius: "5px",
        }}
      >
        <h1>Contact Us</h1>
        <div
          style={{
            marginTop: "20px",
          }}
        >
          <EnquiryForm />
        </div>
      </div>
      <div
        style={{
          width: `${isMobileScreen ? "auto" : "30%"}`,
          padding: "30px",
        }}
      >
        <h1>Company Details</h1>
        <div
          style={{
            padding: "30px",
            border: "1px solid lightgrey",
            borderRadius: "5px",
            marginTop: "20px",
          }}
        >
          <h3>Address :</h3>
          <div className="rows" style={{ marginTop: "10px" }}>
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              style={{ marginRight: "10px" }}
            />
            <p>
              7C1, Thudiyalur Main Road, Idigarai, <br />
              Coimbatore - 641022, Tamil Nadu
            </p>
          </div>
          <h3>Contact Information :</h3>
          <div className="rows" style={{ marginTop: "10px" }}>
            <FontAwesomeIcon
              icon={faEnvelope}
              style={{ marginRight: "10px" }}
            />
            <p>Email: srisgindustrialsolutions@gmail.com</p>
          </div>
          <div className="rows">
            <FontAwesomeIcon icon={faPhone} style={{ marginRight: "10px" }} />
            <p> +91 82205 42826</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
