/* eslint-disable no-undef */
import React, { useState } from "react";
import Banner from "./BannerSlider";
import "./Home.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import ProductSlider from "./ProductSlider";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useTheme, useMediaQuery } from "@mui/material";
import styled from "@emotion/styled";

// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

export default function Home() {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let navigate = useNavigate();
  const getProduct = () => {
    navigate("/product");
  };

  const StyledDiv = styled.div`
    .react-multi-carousel-item {
      width: ${isMobileScreen ? "auto !important" : ""};
    }
  `;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="carousel-button-group">
        <button
          className="left-arrow-btn"
          onClick={() => goToSlide(currentSlide - 1)}
        >
          <FontAwesomeIcon icon={faChevronLeft} size="2x" />
        </button>
        <button
          onClick={() => goToSlide(currentSlide + 1)}
          className="right-arrow-btn"
        >
          <FontAwesomeIcon icon={faChevronRight} size="2x" />
        </button>
      </div>
    );
  };

  const productList = [
    {
      name: "Bopp Tapes",
      image: "./boppTape4.png",
      url: "/bopp-tape",
      description:
        "BOPP is biaxially oriented polypropylene (BOPP) film coated  with water based adhesive and our adhesive tapes are made from superior quality adhesive...",
    },
    {
      name: "PET Strap",
      image: "./pet1.png",
      url: "/pet-strap",
      description:
        "PET strapping can be applied manually with a tensioner and sealer or automatically with pneumatic or Battery powered friction weld tools.",
    },
    {
      name: "Cloth/Cotton Tapes",
      image: "./cottonTape3.png",
      url: "/cotton-cloth-tape",
      description:
        "Cloth tape is an adhesive tape made with a cloth backing to make it durable and flexible. The tape is useful for various purposes...",
    },
    {
      name: "Sacks",
      image: "./Sacks.JPG",
      url: "/pp-woven-sacks",
      description:
        "Pretail Plastic Storage Sacks for rough use or Sack Races, Big Plastic Sack Bags, Woven Sacker, Standard Size...",
    },
    {
      name: "Masking Tapes",
      image: "./mask13.png",
      url: "/masking-tape",
      description:
        "Masking Tape is a type of pressure-sensitive tape made of a thin and easy-to-tear paper, and an easily released pressure-sensitive adhesive...",
    },
    {
      name: "Silica Gel",
      image: "./s1.png",
      url: "/silica-gel",
      description:
        "Silica gel is used as a desiccant to control local humidity to avoid spoilage or degradation of some goods...",
    },
    {
      name: "HDPE Tapes",
      image: "./hdpeTape5.png",
      url: "/hdpe-tape",
      description:
        "A superior quality self adhesive tape which has been specially made for joining and sealing of bags with HDPE base. The tape has high adhesions...",
    },
    {
      name: "Paper Tubes",
      image: "./PaperTube.JPG",
      url: "/paper-tube",
      description:
        "Paper tubes consist of paper or paperboard sheet layers wound together to form strong, hollow, and usually cylindrical shapes. The paper layers are laminated or...",
    },
    {
      name: "Packaging Label",
      image: "./label11.png",
      url: "/packaging-label",
      description:
        "We are specialists in self adhesive label printing and are accredited label suppliers Labels and stickers are what give an identity to a product. ",
    },
    {
      name: "Shrink Pouch",
      image: "./pouch6.png",
      url: "/shrink-pouch",
      description:
        "A  high-quality Shrink Film, Stretch Film, Shrink Bags, Pvc Shrink Label, Pvc Shrink Film etc. Our offered range consists of best grade products.",
    },
    {
      name: "Bubble Roll",
      image: "./roll1.png",
      url: "/bubble-roll",
      description:
        "Bubble wrap is most often formed from polyethylene (low-density polyethylene) film with a shaped side bonded to a flat side to form air bubbles.",
    },
    {
      name: "Stretch Film",
      image: "./film8.png",
      url: "/stretch-film",
      description:
        "Stretch film, sometimes known as pallet wrap, is a highly stretchable plastic film that is wrapped around items. The elastic recovery keeps the items tightly bound.",
    },
    {
      name: "ESD Accessories",
      image: "./esd7.png",
      url: "/esd-accessories",
      description:
        "Static Protection-Personal - Antistatic Protection - Workplace Equipment - Wide offer of products at Transfer Multisort Elektronik.",
    },
    {
      name: "Paint Spray",
      image: "./spray.png",
      url: "/paint-spray",
      description:
        "Trader of a wide range of products which include Multipurpose Spray Paint, Yellow Spray Paint, Pink Spray Paint, Namo Black Spray Paint, Purple Spray Paint and  Red Spray Paint.",
    },
    {
      name: "Stationaries",
      image: "./newsta9.png",
      url: "/stationaries",
      description:
        "Stationery products are evergreen. They find a place in every kind of office and home. So, there is always a large market demand for stationery items.",
    },
    {
      name: "Cleaning Chemicals",
      image: "./clean1.png",
      url: "/cleaning-chemicals",
      description:
        "Cleaning and disinfection prevents microbial contamination, The use of appropriate cleaning and disinfecting agents directly translates into the lifetime of machines and production installations.",
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <Banner />
      <div className={isMobileScreen ? "mobile-info" : "info"}>
        <div className="infoText">
          <h3
            style={{
              fontSize: `${isMobileScreen ? "30px" : "40px"}`,
              marginBottom: `${isMobileScreen ? "20px" : "10px"}`,
              textAlign: "right",
              color: "#262525",
            }}
          >
            Tape Manufacturing & Trading
          </h3>
          <p
            style={{
              fontSize: "16px",
              marginBottom: "10px",
              textAlign: "justify",
              lineHeight: "1.6",
              color: "#444444",
            }}
          >
            Sri SG Industrial Solutions is a company engaged in manufacturing
            and marketing of a wide range of self-adhesive tapes for Industry,
            Automobiles, Electrical, Spinning Mills and other special
            application, including printed tapes, cloth tapes, HDPE tapes,
            labels,Silica gel,Pets strap etc..
            <br />
            <br />~ The Factory is located in Coimbatore and Equipped with tape
            machinery specially made and designed to ensure production and
            supply of best quality products at economical and timely delivery
            and trained manpower to produce product matching specifications. The
            ~ company has wide marketing Network Direct and through dealers
            backed by Factory trained professionals.
            <br />
            <br /> The company has specialised in packaging equipments and also
            supplying for various leading industries and also spinning mills
            production.
          </p>
        </div>
        {!isMobileScreen && (
          <div className="infoImg">
            <img
              src="https://www.wondertape.in/front-assets/img/about/type-of-tapes.jpg"
              alt="allTapes"
            />
          </div>
        )}
      </div>
      <div className="product-view-div">
        <h3
          style={{
            fontSize: `${isMobileScreen ? "30px" : "40px"}`,
            marginBottom: "30px",
            textAlign: "center",
            color: "#262525",
          }}
        >
          Our Product Range
        </h3>
        <StyledDiv
          style={{
            padding: `${
              isMobileScreen ? "0px 35px 30px 35px" : "0px 100px 50px 100px"
            }`,
          }}
        >
          <Carousel
            responsive={responsive}
            additionalTransfrom={0}
            arrows={false}
            autoPlaySpeed={2000}
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            rewind={true}
            rewindWithAnimation={true}
            rtl={false}
            shouldResetAutoplay
            showDots={true}
            sliderClass=""
            swipeable
            customButtonGroup={<ButtonGroup />}
            className="carosel_mobile_item"
          >
            {productList.map((product) => (
              <div
                className={
                  isMobileScreen
                    ? "mobile-product-view product-view"
                    : "product-view"
                }
              >
                <div
                  className={
                    isMobileScreen
                      ? "product-view-img-div mobile-product-view-img-div"
                      : "product-view-img-div"
                  }
                >
                  <img
                    className={
                      isMobileScreen
                        ? "product-view-img mobile-product-view-img"
                        : "product-view-img"
                    }
                    src={product.image}
                    alt="product"
                  />
                </div>
                <div className="product-view-content">
                  <h3>{product.name}</h3>
                  {!isMobileScreen && <p>{product.description}</p>}
                </div>
                <button
                  className="view-button"
                  onClick={() => {
                    navigate(`${product.url}`);
                  }}
                >
                  View Product
                </button>
              </div>
            ))}
          </Carousel>
        </StyledDiv>
      </div>
    </div>
  );
}
