import React, { useState } from "react";
import "./BannerSlider.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import styled, { keyframes } from "styled-components";
import { useTheme, useMediaQuery, Box } from "@mui/material";
import { NavLink } from "react-router-dom";

const titleAnime = keyframes`
0% {opacity: 0; transform: translateY(-100px)}
100% {opacity: 1; transform: translateY(0)}
`;
const textAnime = keyframes`
0% {opacity: 0; transform: translateX(100px)}
100% {opacity: 1; transform: translateY(0)}
`;
const buttonAnime = keyframes`
0% {opacity: 0; transform: translateY(100px)}
100% {opacity: 1; transform: translateY(0)}
`;

const Title = styled.h1`
  display: inline-block;
  opacity: 0;
  animation-name: ${titleAnime};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  // animation-iteration-count: infinite;
  // animation-timing: 5;
`;
const Text = styled.p`
  display: inline-block;
  opacity: 0;
  animation-name: ${textAnime};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  // animation-iteration-count: infinite;
`;
const Button = styled.button`
  display: inline-block;
  opacity: 0;
  animation-name: ${buttonAnime};
  animation-duration: 3s;
  animation-fill-mode: forwards;
  // animation-iteration-count: infinite;
`;

function BannerSlider({ banners }) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [slides, useSlides] = useState([
    "./stationaryB.png",
    "./boppB.png",
    "./petstrapB.png",
    "./clothtapeB.png",
    "./coneB.png",
    "./silicaB.png",
    "./hdpeB.png",
  ]);
  const [path, setPath] = useState([
    "/stationaries",
    "/bopp-tape",
    "/pet-strap",
    "/cotton-cloth-tape",
    "/paper-tube",
    "/silica-gel",
    "/hdpe-tape",
  ]);
  const [title, setTitle] = useState([
    "Stationary Items",
    "Bopp Tapes",
    "PET Strap",
    "Cloth/Cotton Tape",
    "Paper Tubes",
    "Silica Gel",
    "HDPE Tapes",
  ]);
  const [description, setDescription] = useState([
    "Stationery products are evergreen. They find a place in every kind of office and home. So, there is always a large market demand for stationery items.",
    "BOPP is biaxially oriented polypropylene (BOPP) film coated with water based adhesive and our adhesive tapes are made from superior quality adhesive...",
    "PET strapping can be applied manually with a tensioner and sealer or automatically with pneumatic or Battery powered friction weld tools...",
    "Cloth tape is an adhesive tape made with a cloth backing to make it durable and flexible. The tape is useful for various purposes...",
    "Paper tubes consist of paper or paperboard sheet layers wound together to form strong, hollow, and usually cylindrical shapes. The paper layers are laminated or...",
    "Silica gel is used as a desiccant to control local humidity to avoid spoilage or degradation of some goods...",
    "A superior quality self adhesive tape which has been specially made for joining and sealing of bags with HDPE base. The tape has high adhesions...",
  ]);
  return (
    <div
      className="banner"
      style={{
        height: `${!isMobileScreen ? "650px" : "300px"}`,
        borderBottom: "1px solid lightgray",
      }}
    >
      <Carousel
        infiniteLoop
        autoPlay={1500}
        showThumbs={false}
        showArrows={isMobileScreen ? false : true}
        swipeable={isMobileScreen ? true : false}
        className="homeBanner"
        style={{ height: `${!isMobileScreen ? "650px" : "300px"}` }}
      >
        {slides.map((slide, index) => (
          <div
            className="banner-div"
            // style={{
            //   backgroundImage: `url(${slide})`,
            // }}
          >
            <img
              style={{ height: `${!isMobileScreen ? "650px" : "300px"}` }}
              src={slide}
              alt="banner"
            />
            <div
              className={!isMobileScreen ? "banner-content" : "mobile-banner"}
            >
              <Title className={!isMobileScreen ? "title" : "mobile-title"}>
                {title[index]}
              </Title>
              <Text className="text">{description[index]}</Text>

              <NavLink
                className={
                  !isMobileScreen
                    ? "view-product-button"
                    : "mobile-view-product-button"
                }
                to={path[index]}
                style={{ textDecoration: "none" }}
              >
                View Product
              </NavLink>
            </div>
          </div>
        ))}
      </Carousel>
      {/* <img
        src="https://images-na.ssl-images-amazon.com/images/G/01/AmazonExports/Fuji/2020/October/Fuji_Tallhero_Dash_en_US_1x._CB418727898_.jpg"
        alt=""
        className="homeBanner"
      /> */}
    </div>
  );
}

export default BannerSlider;
