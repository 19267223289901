import React from "react";
import Header from "./Header";
import "./About.css";
import { useTheme, useMediaQuery } from "@mui/material";

function About() {
  // const theme = useTheme();
  const isMobileScreen = useMediaQuery("(max-width:800px)");
  return (
    <div
      className="about"
      style={{ padding: `${isMobileScreen ? "20px" : "100px"}` }}
    >
      {!isMobileScreen && (
        <div style={{ width: "50%" }}>
          <img style={{ width: "100%" }} src="./about.png" alt="" />
        </div>
      )}
      <div
        style={{
          width: `${isMobileScreen ? "auto" : "50%"}`,
          color: "#262525",
          padding: "10px",
          fontFamily: "'Inter', sans-serif",
        }}
      >
        <p
          style={{
            fontSize: "24px",
            margin: "0 0 15px 2px",
            fontWeight: "450",
          }}
        >
          About Our company
        </p>
        <p
          style={{
            fontSize: `${isMobileScreen ? "28px" : "36px"}`,
            fontWeight: "500",
            marginBottom: "20px",
          }}
        >
          About Us | Sri SG Industrial <br /> Solutions Trading Company{" "}
        </p>
        <p
          style={{
            fontSize: "18px",
            lineHeight: "1.6",
            marginBottom: "10px",
            color: "#666666",
          }}
        >
          Sri SG industrial Solutions Company. Founded in 2011  Sri SG has
          developed into a first-class products used in a wide range of
          industries. Many of our clients have initiated us to be a valuable
          source for high-quality tape and related products. We are proud to
          offer the highest quality at competitive prices.
        </p>
        {/* <p
          style={{
            fontSize: "18px",
            lineHeight: "1.6",
            marginBottom: "10px",
            color: "#666666",
          }}
        >
          Wonder has grown into a world-class manufacturer of products used in a
          wide range of industries. Many of our clients have found us to be a
          valuable source for high-quality tape and related products. We are
          proud to offer the highest quality at competitive prices.
        </p> */}
        <p
          style={{
            fontSize: "24px",
            fontWeight: "450",
            margin: "20px 0 10px 0",
          }}
        >
          Excellent Customer Service
        </p>
        <p
          style={{
            fontSize: "18px",
            lineHeight: "1.6",
            marginBottom: "10px",
            color: "#666666",
          }}
        >
          Most of our customers are impressed by the excellent customer service
          they get from us. We are always on the lookout for inventive and
          improved ways to make our products even more helpful and competitive
          in price. We are committed to providing the best quality products to
          our customers.
        </p>
        <p
          style={{
            fontSize: "18px",
            lineHeight: "1.6",
            marginBottom: "10px",
            color: "#666666",
          }}
        >
          Our goal is always to strive to do the right thing and help our
          customers stay informed of the latest developments. If you have any
          additional information or suggestions for us, feel free to contact us
          at any time.
        </p>
        {/* <p
          style={{
            fontSize: "18px",
            lineHeight: "1.6",
            marginBottom: "10px",
            color: "#666666",
          }}
        >
          We are committed to providing the best quality products to our
          customers while staying within the confines of the law. Our goal is
          always to strive to do the right thing and help our customers stay
          informed of the latest developments.
        </p> */}
        {/* <p
          style={{
            fontSize: "18px",
            lineHeight: "1.6",
            marginBottom: "10px",
            color: "#666666",
          }}
        >
          Many of our distributors are available to provide support for our
          customers. If you have any additional information or suggestions for
          us, feel free to contact us at any time.
        </p> */}
        <p
          style={{
            fontSize: "24px",
            fontWeight: "450",
            margin: "20px 0 10px 0",
          }}
        >
          Our Core Values
        </p>
        <ul style={{ color: "#666666" }}>
          <li style={{ marginBottom: "10px" }}>Quality at its best.</li>
          <li style={{ marginBottom: "10px" }}>
            Continuous up gradation &amp; Innovation.
          </li>
          <li style={{ marginBottom: "10px" }}>
            Corporate Social Responsibility.
          </li>
          <li style={{ marginBottom: "10px" }}>Caring &amp; Understanding.</li>
        </ul>
      </div>
    </div>
  );
}

export default About;
