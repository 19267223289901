import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import "./EnquiryForm.css";
// import axios from "axios";

function EnquiryForm({ submit }) {
  const [state, setState] = useState({
    name: "",
    email: "",
    number: "",
    city: "",
    message: "",
  });

  // const [submitStatus, setSubmitStatus] = useState(submit);
  const [success, setSuccess] = useState(submit);
  const params = window.location.pathname;

  const sendEmail = async (e) => {
    e.preventDefault();
    const { name, email, message, number, city } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      message: message.value,
      number: number.value,
      city: city.value,
    };
    let response = await fetch("http://localhost:5000/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    // let result = await response.json();
    // setSubmitName(result.status ? "SUBMITTING" : "SUBMIT");
    setState({ name: "", email: "", number: "", city: "", message: "" });
    setSuccess(true);
  };

  useEffect(() => {
    setSuccess(false);
  }, [params]);

  const onInputChange = (event) => {
    const { name, value } = event.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  return (
    <div style={{ textAlign: "center" }}>
      {success ? (
        <div
          style={{
            color: "#1D3150",
            lineHeight: "1.5",
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img
            src="./newSuccessIcon.jpeg"
            alt="Successfully submitted"
            style={{ height: "100px", width: "100px" }}
          />
          <h1>Thank You!</h1>
          <p>Your submission has been received.</p>
        </div>
      ) : (
        <form onSubmit={sendEmail}>
          <Form.Group controlId="name">
            {/* <Form.Label>Full Name</Form.Label> */}
            <Form.Control
              type="text"
              name="name"
              value={state.name}
              placeholder="Customer Name"
              onChange={onInputChange}
              className="textLabel"
            />
          </Form.Group>
          <Form.Group controlId="email">
            {/* <Form.Label>Email</Form.Label> */}
            <Form.Control
              type="text"
              name="email"
              value={state.email}
              placeholder="Customer Email"
              onChange={onInputChange}
              className="textLabel"
            />
          </Form.Group>
          <Form.Group controlId="subject">
            {/* <Form.Label>Subject</Form.Label> */}
            <Form.Control
              type="text"
              name="number"
              value={state.number}
              placeholder="Mobile Number"
              onChange={onInputChange}
              className="textLabel"
            />
          </Form.Group>
          <Form.Group controlId="subject">
            {/* <Form.Label>Message</Form.Label> */}
            <Form.Control
              type="text"
              name="city"
              value={state.city}
              placeholder="City"
              onChange={onInputChange}
              className="textLabel"
            />
          </Form.Group>
          <Form.Group controlId="subject">
            {/* <Form.Label>Message</Form.Label> */}
            <Form.Control
              as="textarea"
              name="message"
              value={state.message}
              rows="3"
              placeholder="Write Your Message"
              onChange={onInputChange}
              className="textLabel"
              style={{
                height: "100px",
              }}
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="submitButton">
            Submit
          </Button>
        </form>
      )}
    </div>
  );
}

export default EnquiryForm;
