import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import "./Footer.css";
import { useTheme, useMediaQuery } from "@mui/material";
import { NavLink } from "react-router-dom";

function Footer() {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const pages = [
    { name: "Home", url: "/" },
    { name: "About US", url: "/about-us" },
    { name: "Our Products", url: "/bopp-tape" },
    { name: "Contact", url: "/contact" },
  ];
  const pageLoad = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      style={{
        padding: `${isMobileScreen ? "40px 40px" : "40px 10% 40px 10%"}`,
        backgroundColor: "#19324F",
        color: "#fff",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: `${isMobileScreen ? "column" : "row"}`,
      }}
    >
      <div style={{ width: `${isMobileScreen ? "100%" : "35%"}` }}>
        <h2 style={{ color: "#FFFFFF", marginBottom: "30px" }}>
          Contact Details
        </h2>
        <h3
          style={{
            fontSize: "30px",
            marginBottom: "20px",
            color: "#ED3833",
            paddingLeft: "30px",
          }}
        >
          Sri SG Industrial Solutions
        </h3>
        <div style={{ paddingLeft: "30px", color: "#fff" }}>
          <div className="rows">
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              style={{ marginRight: "10px" }}
            />
            <p>
              7C1, Thudiyalur Main Road, Idigarai, <br />
              Coimbatore - 641022, Tamil Nadu
            </p>
          </div>
          <div className="rows">
            <FontAwesomeIcon
              icon={faEnvelope}
              style={{ marginRight: "10px" }}
            />
            <a
              style={{ color: "#fff" }}
              href="mailto: srisgindustrialsolutions@gmail.com"
            >
              srisgindustrialsolutions@gmail.com
            </a>
          </div>
          <div className="rows">
            <FontAwesomeIcon icon={faPhone} style={{ marginRight: "10px" }} />
            <p> +91 82205 42826</p>
          </div>
        </div>
      </div>
      <div
        style={{ width: `${isMobileScreen ? "100%" : "35%"}` }}
        id="quick_links"
      >
        <h2 style={{ color: "#FFFFFF", marginBottom: "30px" }}>Quick Links</h2>
        {pages.map((page) => (
          <NavLink
            className="menu_footer"
            to={page.url}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "15px",
              textDecoration: "none",
              color: "#fff",
            }}
            onClick={pageLoad}
          >
            {/* <img
              src="./listArrow.png"
              alt="list"
              style={{
                height: "15px",
                width: "15px",
                marginRight: "8px",
              }}
            /> */}
            <p style={{ fontSize: "18px" }}>{page.name}</p>
          </NavLink>
        ))}
      </div>
    </div>
  );
}

export default Footer;
