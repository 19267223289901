/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import styled from "@emotion/styled";
import { useTheme, useMediaQuery, Box } from "@mui/material";

const BoxStyled = styled(Box)`
  display: flex;
  text-align: center;
  justify-content: space-between;
  background-color: bisque;
  padding: 15px 40px 15px 40px;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    text-align: left;
    justify-content: space-between;
    height: 40px;
  }
`;

function ContactView() {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <BoxStyled className="contact-view">
      <span>
        {!isMobileScreen ? "Contact : " : <FontAwesomeIcon icon={faPhone} />}
        <a href="#"> +91 8610077811</a>
      </span>
      <span>
        {!isMobileScreen ? "Email : " : <FontAwesomeIcon icon={faEnvelope} />}
        <a href="mailto: srisgindustrialsolutions@gmail.com">
          {" "}
          srisgindustrialsolutions@gmail.com
        </a>
      </span>
    </BoxStyled>
  );
}

export default ContactView;
