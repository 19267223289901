/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import { ControlledMenu, Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "./Header.css";
import { NavLink } from "react-router-dom";
import ProductInfo from "./ProductInfo";
import styled from "@emotion/styled";
import { useTheme, useMediaQuery, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import SidebarComponent from "./Sidebar";

const BoxStyled = styled(Box)`
  @media only screen and (max-width: 600px) {
    height: 40px;
    .menu-box {
      display: none;
    }
  }
`;

const MenuButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

const Header = (props) => {
  // let { id } = useParams();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState();
  const [menuOpen, setMenuOpen] = useState();
  // let navigate = useNavigation();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [productPage, setProductPage] = useState([
    "/stationaries",
    "/bopp-tape",
    "/cotton-cloth-tape",
    "/masking-tape",
    "/hdpe-tape",
    "/pet-strap",
    "/silica-gel",
    "/packaging-label",
    "/shrink-pouch",
    "/bubble-roll",
    "/stretch-film",
    "/paper-tube",
    "/esd-accessories",
    "/paint-spray",
    "/cleaning-chemicals",
    "/pp-woven-sacks",
  ]);
  const [products, setProducts] = useState([
    "Stationaries",
    "Bopp Tape",
    "Cotton cloth Tape",
    "Masking Tape",
    "HDPE Tape",
    "PET Strap",
    "Silica Gel",
    "Packaging Labels",
    "Shrink Pouch",
    "Bubble Roll",
    "Stretch Film",
    "Paper Tubes",
    "ESD Accessories",
    "Paint Spray",
    "Cleaning Chemicals",
    "PP woven sacks",
  ]);

  const productInfo = () => {
    return <ProductInfo />;
  };

  const handleCloseMenu = () => {
    console.log("Opsn - ", menuOpen);
    setMenuOpen(false);
  };
  const pageLoad = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <BoxStyled
      style={{ padding: `${isMobileScreen ? "10px 20px" : "15px 60px"}` }}
      id="header_class"
    >
      <div>
        <NavLink to="/" className="headerLink" onClick={pageLoad}>
          <img src="./sgLogo1.PNG" alt="" style={{ height: "65px" }} />
        </NavLink>
      </div>
      {!isMobileScreen ? (
        <div style={{ display: "flex" }} className="menu-box">
          <NavLink to="/" activeClassName="active" onClick={pageLoad}>
            <h5>HOME</h5>
          </NavLink>
          <NavLink to="/about-us" activeClassName="active" onClick={pageLoad}>
            <h5>ABOUT US</h5>
          </NavLink>
          <div className="productList">
            <div className="productMenu">
              <h5
                style={{ marginLeft: "0px" }}
                ref={ref}
                className="btn"
                onPointerEnter={() => setOpen(true)}
              >
                OUR PRODUCTS
              </h5>
              <img
                className={isOpen ? "upArrow" : "downArrow"}
                style={{
                  height: "7px",
                  marginLeft: "5px",
                  marginBottom: "2px",
                }}
                src="./downArrow.png"
                alt="downArrow"
              />
            </div>

            <ControlledMenu
              state={isOpen ? "open" : "closed"}
              anchorRef={ref}
              onPointerLeave={() => setOpen(false)}
              onClose={() => setOpen(false)}
              overflow="auto"
              position="anchor"
            >
              {products.map((product, i) => (
                <MenuItem className="menuItem" onClick={productInfo()}>
                  <NavLink
                    to={productPage[i]}
                    style={{ fontSize: "15px" }}
                    activeClassName="active"
                    onClick={pageLoad}
                  >
                    {product}
                  </NavLink>
                </MenuItem>
              ))}
            </ControlledMenu>
          </div>
          <NavLink to="/contact" activeClassName="active" onClick={pageLoad}>
            <h5>CONTACT</h5>
          </NavLink>
        </div>
      ) : (
        <>
          <Menu
            menuButton={
              <MenuButton>
                <FontAwesomeIcon
                  icon={faBars}
                  size="2x"
                  onClick={() => setMenuOpen(true)}
                />
              </MenuButton>
            }
            state={menuOpen ? "open" : "closed"}
            onPointerLeave={() => setMenuOpen(false)}
            onClose={() => setMenuOpen(false)}
            position="initial"
            direction="left"
          >
            <SidebarComponent closeMenu={handleCloseMenu} />
          </Menu>
        </>
      )}
    </BoxStyled>
  );
};

export default Header;
