import React from "react";
import Header from "./Header";

function Product() {
  return (
    <div>
    </div>
  );
}

export default Product;
