import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./ProductInfo.css";
import { NavLink } from "react-router-dom";
import { Carousel } from "react-carousel-minimal";
import EnquiryForm from "./EnquiryForm";
import ImagesData from "./ImagesData";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useTheme, useMediaQuery, Grid } from "@mui/material";
import { Spinner } from "react-awesome-spinners";

function ProductInfo({ productDetail }) {
  const [activeDescription, setActiveDescription] = useState(true);
  const [activeEnquiry, setActiveEnquiry] = useState(false);
  const params = window.location.pathname;
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const getProduct = () => {
    setActiveDescription(true);
    setActiveEnquiry(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    getProduct();
  }, [params]);

  const [productPage, setProductPage] = useState([
    "/stationaries",
    "/bopp-tape",
    "/cotton-cloth-tape",
    "/masking-tape",
    "/hdpe-tape",
    "/pet-strap",
    "/silica-gel",
    "/packaging-label",
    "/shrink-pouch",
    "/bubble-roll",
    "/stretch-film",
    "/paper-tube",
    "/esd-accessories",
    "/paint-spray",
    "/pp-woven-sacks",
    "/cleaning-chemicals",
  ]);
  const [products, setProducts] = useState([
    "Stationaries",
    "Bopp Tape",
    "Cotton cloth Tape",
    "Masking Tape",
    "HDPE Tape",
    "PET Strap",
    "Silica Gel",
    "Packaging Labels",
    "Shrink Pouch",
    "Bubble Roll",
    "Stretch Film",
    "Paper Tubes",
    "ESD Accessories",
    "Paint Spray",
    "PP woven sacks",
    "Cleaning Chemicals",
  ]);

  const openDescription = () => {
    setActiveDescription(true);
    setActiveEnquiry(false);
  };
  const enquiry = () => {
    setActiveEnquiry(true);
    setActiveDescription(false);
  };

  const data = [
    "./boppTape1.png",
    "./boppTape2.png",
    "./boppTape3.jpeg",
    "./boppTape4.png",
  ];

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };

  return (
    <div
      className="productDetail"
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: `${isMobileScreen ? "column" : "row"}`,
      }}
    >
      <div
        style={{
          width: `${isMobileScreen ? "auto" : "50%"}`,
          padding: `${isMobileScreen ? "20px 10px" : "50px 10px 20px 100px"}`,
        }}
      >
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%", display: "flex", fontSize: "20px" }}>
            <p
              className={`divTitle ${
                activeDescription ? "activeDescription" : "description"
              }`}
              onClick={openDescription}
            >
              DESCRIPTION
            </p>
            <p
              className={`divTitle ${
                activeEnquiry ? "activeEnquiry" : "enquiry"
              }`}
              onClick={enquiry}
            >
              PRODUCT ENQUIRY
            </p>
          </div>
          <div
            className={
              activeDescription ? "descriptionDiv" : "hideDescriptionDiv"
            }
          >
            <ImagesData productName={params} />
            {/* {loading ? <Spinner /> : <ImagesData productName={params} />} */}
          </div>
          <div className={activeEnquiry ? "enquiryDiv" : "hideEnquiryDiv"}>
            <EnquiryForm submit={false} />
          </div>
        </div>
      </div>
      <div
        style={{
          width: `${isMobileScreen ? "auto" : "25%"}`,
          padding: `${isMobileScreen ? "20px 40px" : "50px 10px 20px 20px"}`,
        }}
        id="product_list_class"
      >
        <p
          style={{
            padding: "20px 20px 20px 0",
            color: "#444444",
            fontSize: "20px",
          }}
          onClick={openDescription}
        >
          <b>OUR PRODUCT RANGE</b>
        </p>
        {!isMobileScreen ? (
          products.map((product, i) => (
            <NavLink
              to={productPage[i]}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "15px",
              }}
              onClick={getProduct}
            >
              <img
                src="./listArrow.png"
                alt="list"
                style={{
                  height: "15px",
                  width: "15px",
                  marginRight: "8px",
                }}
                className="customArrow"
              />
              <p style={{ fontSize: "18px" }}>{product}</p>
            </NavLink>
          ))
        ) : (
          <Grid container spacing={1}>
            {products.map((product, i) => (
              <Grid item xs={6}>
                <NavLink
                  to={productPage[i]}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                  onClick={getProduct}
                >
                  <img
                    src="./listArrow.png"
                    alt="list"
                    style={{
                      height: "15px",
                      width: "15px",
                      marginRight: "8px",
                    }}
                    className="customArrow"
                  />
                  <p style={{ fontSize: "18px" }}>{product}</p>
                </NavLink>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </div>
  );
}

export default ProductInfo;
