import React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

export default function imagesData({ productName }) {
  const data = {
    "/bopp-tape": {
      name: "Bopp Tapes",
      images: [
        "./boppTape12.png",
        "./boppTape1.png",
        "./boppTape10.jpeg",
        "./boppTape7.png",
        "./boppTape4.png",
        "./boppTape3.jpeg",
        "./boppTape6.png",
        "./boppTape5.jpeg",
        "./boppTape8.jpeg",
      ],
    },
    "/cotton-cloth-tape": {
      name: "Cloth/Cotton Tapes",
      images: [
        "./cottonTape1.png",
        "./cottonTape2.png",
        "./cottonTape3.png",
        "./cottonTape6.png",
        "./cottonTape5.png",
        "./cottonTape4.png",
        "./cottonTape7.png",
        "./cottonTape8.png",
        "./cloth1.png",
        "./cloth2.png",
        "./cloth3.png",
        "./cloth4.png",
        "./cloth5.png",
        "./cloth7.png",
        "./cloth8.png",
        "./cloth10.png",
        "./cloth14.png",
        "./cloth15.png",
        "./cloth16.png",
        "./cloth18.png",
        "./cloth20.png",
        "./cloth21.png",
      ],
    },
    "/masking-tape": {
      name: "Masking Tapes",
      images: [
        "./mask13.png",
        "./maskingTape4.png",
        "./mask1.png",
        "./mask2.png",
        "./mask4.jpeg",
        "./mask5.jpeg",
        "./mask6.png",
        "./mask7.png",
        "./mask8.png",
        "./mask9.png",
        "./mask10.png",
        "./mask11.png",
        "./mask15.png",
        "./maskingTape1.png",
        "./mask16.png",
      ],
    },
    "/hdpe-tape": {
      name: "HDPE Tapes",
      images: [
        "./hdpe16.png",
        "./hdpe2.png",
        "./hdpeTape5.png",
        "./hdpeTape6.png",
        "./hdpeTape8.png",
        "./hdpeTape9.png",
        "./hdpeTape10.png",
        "./hdpeTape12.png",
        "./hdpe.png",
        "./hdpeTape4.png",
        "./hdpe3.png",
        "./hdpe5.png",
        "./hdpe7.png",
        "./hdpe11.png",
        "./hdpe12.png",
        "./hdpe14.png",
        "./hdpe15.png",
        "./hdpeTape2.png",
      ],
    },
    "/pet-strap": {
      name: "PET Strap",
      images: [
        "./pet1.png",
        "./pet9.png",
        "./pet6.png",
        "./pet4.jpeg",
        "./pet5.png",
        "./pet3.png",
        "./pet7.png",
        "./pet8.png",
        "./pet10.png",
      ],
    },
    "/silica-gel": {
      name: "Silica Gel",
      images: [
        "./s1.png",
        "./s2.png",
        "./s3.png",
        "./s4.png",
        "./s5.png",
        "./gel1.png",
        "./gel2.png",
        "./gel3.png",
        "./gel4.png",
        "./gel6.png",
        "./gel8.png",
        "./gel9.png",
        "./gel11.png",
        "./gel13.png",
        "./gel16.png",
      ],
    },
    "/packaging-label": {
      name: "Packaging Label",
      images: [
        "./newlabel3.png",
        "./newlabel10.png",
        "./newlabel2.png",
        "./label3.png",
        "./newlabel4.png",
        "./newlabel5.jpeg",
        "./newlabel6.png",
        "./label10.png",
        "./newlabel9.png",
        "./newlabel12.png",
        "./newlabel11.png",
        "./newlabel7.png",
      ],
    },
    "/shrink-pouch": {
      name: "Shrink Pouch",
      images: [
        "./pouch1.png",
        "./pouch2.jpeg",
        "./pouch4.png",
        "./pouch6.png",
        "./pouch8.png",
        "./newpouch1.png",
        "./newpouch3.png",
        "./newpouch5.png",
        "./newpouch6.png",
      ],
    },
    "/bubble-roll": {
      name: "Bubble Roll",
      images: [
        "./newbubble6.png",
        "./newbubble8.png",
        "./roll1.png",
        "./roll5.png",
        "./roll4.png",
        "./roll6.png",
        "./newbubble11.png",
        "./roll7.png",
        "./roll8.png",
        "./newbubble1.png",
        "./newbubble2.png",
        "./newbubble3.png",
        "./newbubble10.png",
      ],
    },
    "/stretch-film": {
      name: "Stretch Film",
      images: [
        "./film3.png",
        "./film4.png",
        "./film6.png",
        "./film8.png",
        "./film10.png",
        "./newfilm1.png",
        "./newfilm2.png",
        "./newfilm3.png",
        "./newfilm4.png",
        "./newfilm5.png",
        "./newfilm6.png",
      ],
    },
    "/paper-tube": {
      name: "Paper Tubes/Cone",
      images: [
        "./paper1.png",
        "./newpaper1.png",
        "./newpaper5.png",
        "./newpaper7.png",
        "./newpaper8.png",
        "./newpaper9.png",
        "./newpaper10.jpg",
        "./newpaper11.png",
        "./paper2.png",
        "./paper3.png",
        "./paper5.jpeg",
        "./paper6.png",
        "./paper7.png",
        "./paper11.png",
        "./newpaper3.png",
      ],
    },
    "/esd-accessories": {
      name: "ESD Accessories",
      images: [
        "./esd10.png",
        "./esd11.png",
        "./newesd1.png",
        "./newesd2.png",
        "./newesd3.png",
        "./newesd5.png",
        "./esd1.jpeg",
        "./esd2.png",
        "./esd4.png",
        "./esd5.png",
        "./esd7.png",
        "./esd9.png",
      ],
    },
    "/paint-spray": {
      name: "Paint Spray",
      images: ["./spray.png", "./spray1.png", "./spray3.png", "./spray7.png"],
    },
    "/stationaries": {
      name: "Stationaries",
      images: [
        "./newsta1.png",
        "./newsta2.png",
        "./newsta3.png",
        "./newsta4.png",
        "./newsta5.png",
        "./newsta8.png",
        "./newsta9.png",
        "./newsta10.png",
        "./newsta11.png",
        "./newsta12.png",
        "./newsta13.png",
      ],
    },
    "/pp-woven-sacks": {
      name: "PP Woven Sacks",
      images: [
        "./sack2.png",
        "./sack3.png",
        "./sack4.png",
        "./sack5.png",
        "./sack7.png",
        "./sack8.png",
        "./sack10.png",
      ],
    },
    "/cleaning-chemicals": {
      name: "Cleaning Chemicals",
      images: [
        "./clean1.png",
        "./clean4.png",
        "./clean3.png",
        "./clean2.png",
        "./clean9.png",
        "./clean10.png",
      ],
    },
  };
  let productImages = [];
  let currentProduct = "";
  const getItemsData = Object.keys(data).map((product) => {
    if (product === productName) {
      productImages = data[product]?.images;
      currentProduct = data[product]?.name;
    }
  });

  console.log(productImages);

  return (
    <div>
      <h3
        style={{
          fontSize: "30px",
          marginBottom: "20px",
          color: "#262525",
        }}
      >
        {currentProduct}
      </h3>
      <ImageList sx={{ height: 450 }} cols={3} rowHeight={164}>
        {productImages.map((item) => (
          <ImageListItem key={item}>
            <img src={item} alt={item} />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}
